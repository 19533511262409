import React from 'react';
import axios from 'axios';
import './index.css';
import App from './App';
import AuthProvider from './components/auth/AuthContext';
import OneOffAuthProvider from './components/oneOffAuth/OneOffAuthContext';
import Keycloak from 'keycloak-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { handleTokenExpiredEvent } from './components/auth/TokenUtils';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import { getConfig } from './Config';
import { createRoot } from 'react-dom/client';

const setUpApp = async () => {
  const keycloak = Keycloak('/config/keycloak.json');
  const matomoSiteId = getConfig().matomoSiteId;
  const matomoUrl = getConfig().matomoUrl;
  const authenticated = sessionStorage.getItem('authenticated') === 'true';

  keycloak.onAuthSuccess = async () => {
    sessionStorage.setItem('token', keycloak.token);
    sessionStorage.setItem('idToken', keycloak.idToken);
    sessionStorage.setItem('refreshToken', keycloak.refreshToken);
    sessionStorage.setItem('tokenData', JSON.stringify(keycloak.tokenParsed));
    sessionStorage.setItem('authenticated', 'true');
    await axios.interceptors.request.use(async (request) => {
      const refreshed = await keycloak.updateToken(300);
      if (!refreshed) {
        keycloak.clearToken();
        sessionStorage.clear();
        sessionStorage.setItem('authenticated', 'false');
        keycloak.init({
          promiseType: 'native',
          checkLoginIframe: false,
        });
      }
      sessionStorage.setItem('token', keycloak.token);
      if (keycloak.token) {
        request.headers.Authorization = keycloak.token;
      }
      return request;
    });
  };

  keycloak.onAuthLogout = () => {
    sessionStorage.clear();
    sessionStorage.setItem('authenticated', 'false');
  };

  const instance = createInstance(
    {
      urlBase: matomoUrl,
      siteId: matomoSiteId,
      trackerUrl: `${matomoUrl}/matomo.php`,
      srcUrl: `${matomoUrl}/matomo.js`,
      disabled: false,
      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    },
    [matomoSiteId, matomoUrl]
  );

  const renderApp = async () => {
    if (!authenticated) {
      await keycloak.init({
        promiseType: 'native',
        checkLoginIframe: false,
        redirectUri: window.location,
      });
    } else {
      const idToken = sessionStorage.getItem('idToken');
      const refreshToken = sessionStorage.getItem('refreshToken');
      await keycloak.init({
        promiseType: 'native',
        checkLoginIframe: false,
        onLoad: 'check-sso',
        idToken,
        refreshToken,
        redirectUri: window.location,
      });
    }
    const root = createRoot(document.getElementById('root'));
    root.render(
      <MatomoProvider value={instance}>
        <AuthProvider keycloak={keycloak}>
          <OneOffAuthProvider>
            <Router>
              <App keycloak={keycloak} />
            </Router>
          </OneOffAuthProvider>
        </AuthProvider>
      </MatomoProvider>
    );
  };

  handleTokenExpiredEvent(keycloak);
  await renderApp();
};

await setUpApp();
