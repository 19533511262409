import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Select, FormGroup } from '../../components/Govuk';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { AuthContext } from '../../components/auth/AuthContext';
import { getRetailers, postFile, getSchema } from './api';
import { useMatomo } from '@datapunt/matomo-tracker-react';

const FileUpload = (props) => {
  const [file, setFile] = useState(null);
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [retailerChoice, setRetailerChoice] = useState('');
  const [uploadError, setUploadError] = useState({
    serverError: false,
    virusError: false,
    fileEmptyError: false,
    fileTypeError: false,
    fileSizeError: false,
    fileSizeZeroError: false,
    fileNotMappedError: false,
  });
  const { trackPageView, trackEvent } = useMatomo();
  const { isDeepSupportOrHomeOffice } = useContext(AuthContext);
  const [disabled, setDisabled] = useState(false);

  const mainWrapperError = 'govuk-form-group govuk-form-group--error';
  const mainWrapperNoError = 'govuk-form-group';
  const spanErrorClass = 'govuk-error-message';

  useEffect(() => {
    setUploadError({
      serverError: false,
      virusError: false,
      fileEmptyError: false,
      fileTypeError: false,
      fileSizeError: false,
      fileSizeZeroError: false,
    });

    trackPageView();
    trackEvent({
      category: 'Upload a report',
      action: 'Visited',
    });
  }, [file]);

  useEffect(() => {
    if (isDeepSupportOrHomeOffice()) {
      getRetailers()
        .then((res) => {
          const options = [];
          options.push({ value: '', description: '' });
          res.data.forEach((retailer) => {
            options.push({ value: retailer, description: retailer });
          });
          options.sort();
          setRetailerOptions(options);
        })
        .catch(() => {
          props.history.push('/sar-error');
        });
    }
  }, []);

  const isBadFileResponse = (response) => {
    return (
      response.status === 400 &&
      response.data.includes('Unsuccessful save of incoming retailer data')
    );
  };

  const isVirusResponse = (response) => {
    return response.status === 400 && response.data.includes('virus');
  };

  const isInvalidFileTypeResponse = (response) => {
    return response.status === 415 && response.data.includes('type');
  };

  const isFileSizeErrorResponse = (response) => {
    return response.status === 413;
  };

  const isFileSizeZeroErrorResponse = (response) => {
    return response.status === 422;
  };

  const handleChange = (fileList) => {
    if (fileList) {
      const inputFile = fileList[0];
      setFile(inputFile);
    }
  };

  const uploadFile = () => {
    setDisabled(true);
    if (!file) {
      setUploadError({ fileEmptyError: true });
      setDisabled(false);
    } else {
      setUploadError({ fileEmptyError: false });

      const headers = retailerChoice
        ? {
            accept: 'application/json',
            'content-type': 'multipart/form-data',
            retailer: retailerChoice,
          }
        : { accept: 'application/json', 'content-type': 'multipart/form-data' };

      const config = {
        headers,
      };

      const formData = new FormData();
      formData.append('file', file);

      postFile(formData, config)
        .then((response) => {
          const responseUuid = response.data.guid;
          props.setUuid(responseUuid);
          props.history.push('/success');
          trackEvent({
            category: 'File Upload',
            action: 'Successful',
          });
        })
        .catch((error) => {
          setDisabled(false);
          if (error.response) {
            if (isBadFileResponse(error.response)) {
              setUploadError({ fileNotMappedError: true });
            }
            if (isVirusResponse(error.response)) {
              setUploadError({ virusError: true });
            }
            if (isInvalidFileTypeResponse(error.response)) {
              setUploadError({ fileTypeError: true });
            }
            if (isFileSizeErrorResponse(error.response)) {
              setUploadError({ fileSizeError: true });
            }
            if (isFileSizeZeroErrorResponse(error.response)) {
              setUploadError({ fileSizeZeroError: true });
            }
            trackEvent({
              category: 'File Upload',
              action: 'Failed',
            });
          }
        });
    }
  };

  const getWrapperClass = () => {
    let className = mainWrapperNoError;
    if (
      uploadError.fileEmptyError ||
      uploadError.virusError ||
      uploadError.fileTypeError ||
      uploadError.fileSizeError ||
      uploadError.fileSizeZeroError ||
      uploadError.fileNotMappedError
    ) {
      className = mainWrapperError;
    }
    return className;
  };

  const getSpanClass = () => {
    let className = '';
    if (
      uploadError.fileEmptyError ||
      uploadError.virusError ||
      uploadError.fileTypeError ||
      uploadError.fileSizeError ||
      uploadError.fileSizeZeroError ||
      uploadError.fileNotMappedError
    ) {
      className = spanErrorClass;
    }
    return className;
  };

  const getErrorMessage = () => {
    if (uploadError.fileEmptyError) {
      return (
        <>
          <span className="govuk-visually-hidden" data-test="no-file-error">
            Error:
          </span>
          Select a report
        </>
      );
    } else if (uploadError.virusError) {
      return (
        <>
          <span className="govuk-visually-hidden">Error:</span>The selected file
          contains a virus
        </>
      );
    } else if (uploadError.fileTypeError) {
      return (
        <>
          <span className="govuk-visually-hidden">Error:</span>
          The selected file must be a JSON, CSV, XLS or XLSX
        </>
      );
    } else if (uploadError.fileSizeError) {
      return (
        <>
          <span className="govuk-visually-hidden">Error:</span>The selected file
          must be smaller than 20MB
        </>
      );
    } else if (uploadError.fileSizeZeroError) {
      return (
        <>
          <span className="govuk-visually-hidden">Error:</span>The selected file
          must not be empty.
        </>
      );
    } else if (uploadError.fileNotMappedError) {
      return (
        <>
          <span className="govuk-visually-hidden">Error:</span>The file contents
          is in an unknown schema format. This means the columns or fields are
          unrecognised. Please contact{' '}
          <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
            ChemicalReportingTeam@homeoffice.gov.uk
          </a>{' '}
          to rectify this issue.
        </>
      );
    }
  };

  const getFileUploadClass = () => {
    let className = 'govuk-file-upload';
    if (
      uploadError.fileEmptyError ||
      uploadError.virusError ||
      uploadError.fileTypeError ||
      uploadError.fileSizeError ||
      uploadError.fileSizeZeroError ||
      uploadError.fileNotMappedError
    ) {
      className = className + ' govuk-file-upload--error';
    }
    return className;
  };

  const handleRetailerChange = (retailer) => {
    setRetailerChoice(retailer);
  };

  const downloadSchema = (type) => {
    getSchema(type).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      // set the file type for CSV as XLSX as this is the actual download type
      let fileName = '';
      switch (type) {
        case 'json':
          fileName = 'SAR_schema.json';
          break;
        case 'xml':
          fileName = 'SAR_schema.xml';
          break;
        case 'csv':
          fileName = 'SAR_schema_guidance.xlsx';
          break;
        case 'csv-header':
          fileName = 'SAR_schema.csv';
          break;
      }
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      trackEvent({
        category: `${type} Schema`,
        action: 'Downloaded',
      });
    });
  };

  return (
    <div>
      <main className="govuk-main-wrapper" id="content" role="main">
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-heading-l" data-test="upload-header">
              Upload data
            </h1>
            <p className="govuk-body" data-test="upload-message-1">
              The service may not be able to process your data if it is not
              submitted correctly. The guidance document below explains how to
              submit data via JSON, CSV, XLS and XLSX formats.
            </p>
            <ul className="govuk-list govuk-list--bullet">
              <li>
                <a
                  href="#"
                  className="govuk-link"
                  data-test={'csv-schema-link'}
                  onClick={() => downloadSchema('csv')}
                >
                  Guidance for submitting data
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="govuk-link"
                  data-test={'json-schema-link'}
                  onClick={() => downloadSchema('json')}
                >
                  Download JSON schema
                </a>
              </li>
              <li>
                <a
                  href="#"
                  className="govuk-link"
                  data-test={'csv-header-link'}
                  onClick={() => downloadSchema('csv-header')}
                >
                  Download CSV schema
                </a>
              </li>
            </ul>

            <h2 className="govuk-heading-s">Upload data</h2>
            {isDeepSupportOrHomeOffice() && (
              <FormGroup>
                <Select
                  label={'Upload SAR on behalf of a retailer:'}
                  name="behalf-of-retailer-select"
                  id="behalf-of-retailer-select"
                  onChange={handleRetailerChange}
                  options={retailerOptions}
                  selected={retailerChoice}
                  dataTest="behalf-of-retailer-select"
                />
              </FormGroup>
            )}
            <FormGroup>
              <div className={getWrapperClass()}>
                {uploadError.fileEmptyError ||
                uploadError.virusError ||
                uploadError.fileTypeError ||
                uploadError.fileSizeZeroError ||
                uploadError.fileSizeError ||
                uploadError.fileNotMappedError ? (
                  <span
                    id="file-upload-1-error"
                    data-test="file-upload-error"
                    className={getSpanClass()}
                  >
                    {getErrorMessage()}
                  </span>
                ) : (
                  <span id="file-upload-1-error" style={{ display: 'none' }} /> //to fulfil aria described-by reference
                )}

                <label style={{ display: 'none' }} htmlFor="file-upload-1">
                  Upload file
                </label>
                <input
                  onChange={(e) => handleChange(e.target.files)}
                  className={getFileUploadClass()}
                  id="file-upload-1"
                  name="file-upload-1"
                  type="file"
                  aria-describedby="file-upload-1-error"
                  title=" "
                />
              </div>
            </FormGroup>
          </div>

          <div className="govuk-grid-column-two-thirds">
            <Button
              content={disabled ? 'Loading..' : 'Upload'}
              action={uploadFile}
              disabled={disabled}
            />
            <h2 className="govuk-heading-s">Need help?</h2>
            <p className="govuk-body" data-test="upload-contact-1">
              For any issues, email the team at{' '}
              <a
                href="mailto:ChemicalReportingTeam@homeoffice.gov.uk"
                data-test="upload-contact-1-link"
              >
                ChemicalReportingTeam@homeoffice.gov.uk
              </a>
            </p>
          </div>
        </div>
      </main>
    </div>
  );
};

const FileUploadWithAuth = withAuthorisation({ forwardUrl: '/upload' })(
  withRouter(FileUpload)
);

export { FileUploadWithAuth as FileUpload };
