import React, { useEffect, useContext, useState } from 'react';
import { sendFormAnswers } from '../api';
import { OneOffAuthContext } from '../../../components/oneOffAuth/OneOffAuthContext';
import { LoadingSpinner } from '../../../components/Govuk';
import { Link, useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { escapeHtmlFromData } from '../utils';
import { STORE_KEY } from '../index';

export const Uploaded = ({ sarData }) => {
  const {
    getIDToken,
    setAuthenticated,
    clearTokens,
    getValidToken,
    isOneOffAuthenticated,
  } = useContext(OneOffAuthContext);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [uuid, setUuid] = useState(null);
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  sarData = escapeHtmlFromData(sarData);
  document.title = 'Thanks - Report Suspicious Chemical Activity';

  useEffect(() => {
    if (!sarData) {
      return;
    }
    if (isOneOffAuthenticated()) {
      getValidToken()
        .then(() => performSubmit())
        .catch(() => history.push('/something-went-wrong'));
    } else {
      performSubmit();
    }
  }, [isOneOffAuthenticated]);

  function performSubmit() {
    sendFormAnswers(sarData, getIDToken())
      .then(handleDone)
      .catch(() => history.push('/one-off-sars-error'));
  }

  function handleDone(res) {
    setFormSubmitted(true);
    setUuid(res.data.guid);
    trackPageView();
    sessionStorage.removeItem(STORE_KEY);
    if (isOneOffAuthenticated()) {
      setAuthenticated(false);
      clearTokens();
      trackEvent({
        category: 'One off SAR',
        action: 'Completed',
      });
    } else {
      trackEvent({
        category: 'Registered one off SAR',
        action: 'Completed',
      });
    }
  }

  return (
    <main className="govuk-main-wrapper" id="content" role="main" tabIndex={0}>
      {formSubmitted ? (
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <div className="govuk-panel govuk-panel--confirmation">
              <h1 className="govuk-panel__title">Report complete</h1>
              <div className="govuk-panel__body">
                <p> Your Suspicious Activity Report ID</p>
                <strong id="data-uuid" data-test="success-uuid">
                  {uuid}
                </strong>
              </div>
            </div>
            <div className="govuk-body">
              Thank you for taking the time to report this suspicious activity.
              We have sent you a confirmation email.
              <br />
            </div>
            <div className="govuk-body">
              <h2 className="govuk-heading-m"> What happens next</h2>
              <p> We've sent your application to the Home Office.</p>
              <p>
                {' '}
                The police may contact you if required via email for further
                information. If possible, keep any evidence you have of the
                incident for six months.
              </p>
              <h2 className="govuk-heading-m">For enquiries</h2>
              If you wish to follow up on this report, please contact{' '}
              <a
                className="govuk-link"
                href="mailto:chemical.reporting@met.police.uk"
              >
                chemical.reporting@met.police.uk
              </a>{' '}
              and quote your Suspicious Activity Report ID.
              <br /> <br />{' '}
              <Link to="/login" className="govuk-link">
                Return to the home page
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <LoadingSpinner data-test="spinner" alt="Loading results spinner" />
      )}
    </main>
  );
};
